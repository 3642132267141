import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "progress-manufacturing-styleguide"
    }}>{`Progress Manufacturing Styleguide`}</h1>
    <p>{`This style guide allows Progress Manufacturing developers to quickly spin up a front-end development environment and follow established styles.`}</p>
    <p>{`Progress Manufacturing uses a set of forked React Components from the well established `}<a parentName="p" {...{
        "href": "https://material-ui.com/"
      }}><em parentName="a">{`Material-UI`}</em></a>{` framework as well as Google's own `}<a parentName="p" {...{
        "href": "https://material.io/design/"
      }}><em parentName="a">{`material style guidelines`}</em></a>{`. `}</p>
    <p>{`Each of these components and guides allows for rapid development across all Progress Manufacturing brands: `}<a parentName="p" {...{
        "href": "htttps://www.progressmfg.com"
      }}><em parentName="a">{`Progress Manufacturing`}</em></a>{`, `}<a parentName="p" {...{
        "href": "https://www.equalizerhitch.com"
      }}><em parentName="a">{`Equal-i-zer Hitch`}</em></a>{`, and `}<a parentName="p" {...{
        "href": "https://www.fastwaytrailer.com"
      }}><em parentName="a">{`Fastway Trailer`}</em></a>{`.`}</p>
    <p>{`This styleguide can be used in conjungtion with multiple different frameworks. However the main frameworks used to build applications and website are `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}><em parentName="a">{`GatsbyJS`}</em></a>{` and `}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}><em parentName="a">{`NextJS`}</em></a>{`. Both of which have their unique advantages when developing the front-end builds for Progress Mfg. Gatsby is primarily used for our websites and e-commerce applications while Nextjs is used for our more full featured applications. Below is a breakdown of frameworks used in conjunction with their applications.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Framework`}</strong></th>
          <th parentName="tr" {...{
            "align": "center"
          }}><strong parentName="th">{`Website/Application`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`progressmfg.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`gatsbyjs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`bpp.progressmfg.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`gatsbyjs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`gethelp.progressmfg.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`nextjs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`vault.progressmfg.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`nextjs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`equalizerhitch.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`gatsbyjs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`fastwaytrailer.com`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`gatsbyjs`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      